import React from "react";
import { Route, Redirect } from "react-router-dom";
import AdminLayout from '../layouts/Admin';

const Protected = ({ userType }) => {
    return (<Route
        render={() => {
            if (userType == "logged-out") {
                return (<Redirect
                    to={{
                        pathname: "/admin"
                    }}
                />)
            } else if (userType == "admin") {
                    return (<AdminLayout />)
            }
        }} />)
};

export default Protected;