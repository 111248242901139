import { apiLoginUser } from './Consts';

export var userLoginAPI = (data) => {
    return new Promise((resolve, reject) => {
        fetch(apiLoginUser, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { 
                'Content-Type': 'application/json' ,
            }
        })
            .then(responseData => responseData.json())
            .then((response, error) => {
                if (response) {
                    return resolve(response)
                } else {
                    return reject(error)
                }
            })
            .catch(error => {
                console.log( ">>>>>>>", error);
            })
    })
}

