import { useEffect, useMemo, useState } from "react";
import Header from "components/Headers/Header";
import { Card, CardHeader, Container, Row } from "reactstrap";
import UploadVideoModal from "../components/Popup/UploadVideoModal";
import TableContainer from "components/TableContainer";
import { getAllVideos } from "services/Device";
import DeleteVideoModal from "../components/Popup/DeleteVideoModal";
import VideoPlayer from "../components/Popup/VideoPlayer";

const VideoList = () => {
    const [videosData, setVideosData] = useState([]);
    const [videoDataSearch, setVideoDataSearch] = useState([]);
    const [uploadVideo, setUploadVideo] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedTitle, setSelectedTitle] = useState("");
    const [videoDisplay, setVideoDisplay] = useState(false);
    const [videoUrl, setVideoUrl] = useState("");

    const toggle = () => {
        setDeleteModal(false);
        setUploadVideo(false);
        setVideoDisplay(false);
    }
    const getData = async () => {
        const response = await getAllVideos();
        setVideosData(response.result.data);
        setVideoDataSearch(response.result.data);
    }
    const filterData = async (text) => {
        text && text.length > 1 ?
            setVideosData(videoDataSearch.filter((item) => String(item.title).toLowerCase().includes(String(text).toLowerCase()))) : setVideosData(videoDataSearch)
    }
    const convertBytestoMB = (value) => {
        const sizeInMb = value / (1024 ** 2)
        return sizeInMb.toFixed(2);
    }
    const refreshlist = async () => {
        await getData();
    }
    useEffect(() => {
        getData()
    }, [])
    const columns = useMemo(
        () => [
            {
                Header: "NO",
                accessor: "id",
                Cell: (cellProps) => (
                    <>
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1 ms-2 name text-center m-1">
                                {cellProps.row.index + 1}
                            </div>
                        </div>
                    </>
                ),
            },
            {
                Header: "Title",
                accessor: "title",
                Cell: (cellProps) => (
                    <>
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1 ms-2 name text-center m-1">
                                {cellProps.row.original.title}
                            </div>
                        </div>
                    </>
                ),
            },
            {
                Header: "Size",
                accessor: "size",
                Cell: (cellProps) => (
                    <>
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1 ms-2 name text-center m-1">
                                {convertBytestoMB(cellProps.row.original.size)} MB
                            </div>
                        </div>
                    </>
                ),
            },
            {
                Header: "Play",
                accessor: "play",
                Cell: (cellProps) => (
                    <>
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1 ms-2 name text-center m-1">
                                <i className="ni ni-button-play" onClick={()=>{
                                    setVideoUrl(cellProps.row.original.filename)
                                    setVideoDisplay(true)
                                }}></i>
                            </div>
                        </div>
                    </>
                ),
            },
            {
                Header: "Delete",
                accessor: "delete",
                Cell: (cellProps) => (
                    <>
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1 ms-2 name text-center m-1">
                                <i className="fa fa-trash" onClick={() => {
                                    setSelectedTitle(cellProps.row.original.title)
                                    setDeleteModal(true)
                                }}></i>
                            </div>
                        </div>
                    </>
                ),
            },
        ],
        []
    );
    return (
        <>
            <div>
                <VideoPlayer
                    videoDisplay={videoDisplay}
                    setVideoDisplay={setVideoDisplay}
                    toggle={toggle}
                    path={videoUrl}
                />
                <UploadVideoModal
                    uploadVideoModal={uploadVideo}
                    setUploadVideoModal={setUploadVideo}
                    toggle={toggle}
                    refreshData={getData}
                />
                <DeleteVideoModal
                    deleteModal={deleteModal}
                    setDeleteModal={setDeleteModal}
                    toggle={toggle}
                    title={selectedTitle}
                    refreshData={getData}
                />
                <Header />
                <Container className="mt--7" fluid style={{ marginTop: '-8rem !important' }}>
                    <Row>
                        <div className="col">
                            <Card className="shadow" style={{ height: '85vh' }}>
                                <CardHeader className="border-0">
                                    <h3 className="mb-0"> Videos List
                                        <div style={{ float: 'right' }}>
                                            <i id="uploadvideo" style={{ cursor: 'pointer', visibility: 'visible', color: '#000000', marginTop: 5, transition: 'transform .5s linear', transformStyle: 'preserve-3D', position: 'sticky', marginRight: 20 }} onClick={() => { setUploadVideo(true) }} className="fas fa-plus" />
                                            <i id="refreshbtn" style={{ cursor: 'pointer', visibility: 'visible', color: '#000000', marginTop: 5, transition: 'transform .5s linear', transformStyle: 'preserve-3D', position: 'sticky', rotationPoint: '50% 50%' }} onClick={() => { refreshlist() }} className="fas fa-sync" />
                                        </div>
                                    </h3>
                                    <div className="input-group" style={{ margin: 10, marginLeft: 0, width: "55%", height: 30 }}>
                                        <span className="input-group-text text-body"><i className="fas fa-search" aria-hidden="true"></i></span>
                                        <input type="text" className="form-control" placeholder="Enter Title" style={{ paddingLeft: 10 }} onChange={(e) => { filterData(e.target.value) }} />
                                    </div>
                                </CardHeader>
                                <TableContainer
                                    columns={columns}
                                    data={videosData || []}
                                    customPageSize={10}
                                    className="custom-header-css"
                                >
                                </TableContainer>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </div>
        </>
    )
}
export default VideoList;