import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {removeDeviceSession} from '../../services/Device.js';
function MyVerticallyCenteredModal(props) {
    const deleteRecord = (data) =>{
        removeDeviceSession(data._id);
        window.location.reload(true);
        props.onHide();
    }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h3>Do you want to delete the record ?</h3>
      </Modal.Body>
      <Modal.Footer>
        <Button style={{backgroundColor:"red", borderColor:"red"}} onClick={()=>{deleteRecord(props.recorditem)}}>Delete</Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MyVerticallyCenteredModal;