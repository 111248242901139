import React, { useState } from 'react';
import { Input, Label, Modal, Button, ModalHeader, ModalFooter, ModalBody, FormGroup, Form } from "reactstrap";
import { sendNotificationApi } from 'services/Device'

const NotifyModel = ({ notificationModel, setNotificationModel, toggle, notifyDetails }) => {
  const [title, setTitle] = useState('Telepathy Lamp');
  const [msg, setMsg] = useState('Welcome to Telepthy lamp');
  const [imageUrl, setImageUrl] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");
  const handleClose = () => {
    setNotificationModel(false)
  }

  const handleOnSendPushNotification = async () => {
    console.log("notifyDetails ===>", notifyDetails)
    let detailsObj = {
      to: notifyDetails?.token,
      "notification": {
        title: title,
        body: msg,
        imageUrl: imageUrl,
        redirectUrl: redirectUrl,
        "content_available": true,
        "priority": "high",
      }
    }
    console.log(">detailsObj111", detailsObj);
    await sendNotificationApi(detailsObj);
    handleClose();
  }
  return (<>
    <Modal
      isOpen={notificationModel} toggle={toggle}>
      <ModalHeader >
        <a style={{ fontSize: 'large' }}>Create Notification </a>
      </ModalHeader>
      <ModalBody>
        <Form >
          <FormGroup >
            <Label className="floating-label" htmlFor="title" style={{ color: "#000" }}>Title</Label>
            <Input
              // value={title}
              onChange={(e) => { setTitle(e.target.value) }}
              type="text"
              name="title"
              className="form-control-alternative"
              id="categoryCode"
              defaultValue={title}
              placeholder="Enter Title"
              required="" />
          </FormGroup>
          <FormGroup >
            <Label className="floating-label" htmlFor="message" style={{ color: "#000" }}>Message</Label>
            <Input
              // value={msg}
              defaultValue={msg}
              onChange={(e) => { setMsg(e.target.value) }}
              type="textarea"
              name="message"
              rows="5"
              className="form-control-alternative"
              id="message"
              placeholder="Enter Message"
            />
          </FormGroup>
          <FormGroup >
            <Label className="floating-label" htmlFor="message" style={{ color: "#000" }}>Image URL</Label>
            <Input
              // value={msg}
              defaultValue={imageUrl}
              onChange={(e) => { setImageUrl(e.target.value) }}
              type="text"
              name="Image URL"
              rows="5"
              className="form-control-alternative"
              id="imageUrl"
              placeholder="Image URL"
            />
          </FormGroup>
          <FormGroup >
            <Label className="floating-label" htmlFor="message" style={{ color: "#000" }}>Redirect URL</Label>
            <Input
              // value={msg}
              defaultValue={redirectUrl}
              onChange={(e) => { setRedirectUrl(e.target.value) }}
              type="text"
              name="Redirect URL"
              rows="5"
              className="form-control-alternative"
              id="redirectUrl"
              placeholder="Image URL"
            />
          </FormGroup>

        </Form>
      </ModalBody>
      <ModalFooter>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button style={{ backgroundColor: "#CFD2D8" }} variant="primary" onClick={handleOnSendPushNotification}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  </>)
}
export default NotifyModel;