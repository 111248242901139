import DeviceList from "../views/DeviceList";
import DeviceSessions from '../views/DeviceSessions';
import Notifications from "../views/Notifications";
import VideoList from "../views/VideoList";

var routes = [
  {
    path: "/device-list",
    name: "Dashboard",
    icon: "ni ni-tv-2 ",
    component: DeviceList,
    layout: "",
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: "ni ni-bell-55 ",
    component: Notifications,
    layout: "",
  },
  {
   path: "/video-list",
   name: "Videos",
   icon: "fas fa-video",
   component: VideoList,
   layout: ""
  },
  {
    path: "/device/:id",
    // name: "Details",
    // icon: "ni ni-tv-2 text-primary",
    component: DeviceSessions,
    layout: "",

  },
  { path: '/', pathTo: '/device-list', redirect: true },
];
export default routes;
