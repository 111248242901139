import React, { useState, useEffect, useMemo } from 'react';
import { Card, CardHeader, Container, Row } from "reactstrap";
import Header from "components/Headers/Header.js";
import { deviceNotificationAPI } from '../services/Device';
import NotifyModal from '../components/Popup/NotifyModal';
// import { askForPermissioToReceiveNotifications } from './push-notification';
import TableContainer from 'components/TableContainer';

const Notifications = () => {
  const [notificationModel, setNotificationModel] = useState(false);
  const [notificationDetails, setNotificationDetails] = useState(false);
  const [deviceNotificationData, setDeviceNotificationData] = useState([])
  const [loading, setLoading] = useState(false);

  console.log("data >>>>>", deviceNotificationData)

  const toggle = () => setNotificationModel(!notificationModel);

  const refreshlist = () => {
    setLoading(true);
    document.getElementById("refreshbtn").className = "fas fa-sync fa-spin";
    getdata();
    if (loading) { setLoading(false) }
  }

  const getdata = () => {
    setLoading(true);
    getAllDeviceNotification();
  }

  useEffect(() => {
    getdata()
  }, [])

  const getAllDeviceNotification = () => {
    deviceNotificationAPI().then((data) => {
      console.log("hiten --->", data)
      if (data.result.type === "success") {
        console.log("data notification ---->>", data.result.users)
        setDeviceNotificationData(data.result.users)
        setLoading(false);
        document.getElementById("refreshbtn").className = "fas fa-sync fa-stop"
      } else {
        setLoading(true);
      }
    })
  }
  const showShortLocation = (value) => {
    value = "" + value;
    if (value.length > 25) {
      value = value.substr(0, 31) + "...";
    } else {
      value;
    }
    return value;
  }
  const handleShow = (item) => {
    setNotificationDetails(item);
    setNotificationModel(true);
  }

  // const notificationListRecords = () => {
  //   if (deviceNotificationData.length > 0 && !loading) {
  //     return deviceNotificationData.map((item,index) => {
  //       return (
  //         <tbody key={index}>
  //           <tr>
  //             <td style={{color: "#000", textAlign: "left" }}>{(index + 1)|| "-"}</td>
  //             <td style={{color: "#000", textAlign: "left" }}>{showShortLocation(item.token) || "-"}</td>
  //             <td style={{color: "#000", textAlign: "left" }}>{item.platform || "-"}</td>
  //             <td style={{color: "#000", textAlign: "left" }}>{item.appVersion || "-"}</td>
  //             <td style={{color: "#000", textAlign: "left" }}>
  //               <i style={{color: "#000", cursor: "pointer" }} onClick={() => {
  //                 handleShow(item)
  //                 // askForPermissioToReceiveNotifications();
  //                 }} className="fas fa-comment"></i>
  //             </td>
  //           </tr>
  //         </tbody>
  //       )
  //     })
  //   } else {
  //     return (
  //       <>
  //         {loading ?
  //           <tbody>
  //             <tr>
  //               <th colSpan="5">
  //                 <Spinner animation="border" size="md" />
  //               </th>
  //             </tr>
  //           </tbody>
  //           :
  //           <tbody>
  //             <tr >
  //               <th colSpan="5" style={{ textAlign: 'center' }}>No Record Found!</th>
  //             </tr>
  //           </tbody>
  //         }
  //       </>
  //     )
  //   }
  // }

  const columns = useMemo(
    () => [

      {
        Header: "NO",
        accessor: "id",
        Cell: (cellProps) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 ms-2 name text-center m-1">
                {cellProps.row.index + 1}
              </div>
            </div>
          </>
        ),
      },

      // {
      //   Header: "EMAIL",
      //   accessor: "email",
      //   Cell: (cellProps) => (
      //     <>
      //       <div className="d-flex align-items-center">
      //         <div className="flex-grow-1 ms-2 name text-center m-1">
      //           {cellProps.row.original.email}
      //         </div>
      //       </div>
      //     </>
      //   ),
      // },

      {
        Header: "TOKEN",
        accessor: "token",
        Cell: (cellProps) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 ms-2 name text-center m-1">
                {showShortLocation(cellProps.row.original.token)}
              </div>
            </div>
          </>
        ),
      },

      {
        Header: "PLATFORM",
        accessor: "platform",
        Cell: (cellProps) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 ms-2 name text-center m-1 ">
                {cellProps.row.original.platform}
              </div>
            </div>
          </>
        ),
      },
      {
        Header: "APP VERSION",
        accessor: "appVersion",
        Cell: (cellProps) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 ms-2 name text-center m-1 ">
                {cellProps.row.original.appVersion}
              </div>
            </div>
          </>
        ),
      },

      // {
      //   Header: "CREATE",
      //   accessor: "message",
      //   Cell: (cellProps) => (
      //     <>
      //       <div className="d-flex align-items-center">
      //         <div className="flex-grow-1 ms-2 name text-center m-1 ">
      //           <i style={{ color: "#000", cursor: "pointer" }} onClick={() => {
      //             handleShow(cellProps.row.original)
      //           }} className="fa fa-paper-plane"></i>
      //         </div>
      //       </div>
      //     </>
      //   ),
      // },
    ],
    []
  );


  return (
    <>
      <div >
        <NotifyModal notificationModel={notificationModel} setNotificationModel={setNotificationModel} toggle={toggle} notifyDetails={notificationDetails} />
        <Header />
        <Container className="mt--7" fluid style={{ marginTop: '-8rem !important' }}>
          <Row>
            <div className="col">
              <Card className="shadow" style={{ height: '82vh'}}>
                <CardHeader className="border-0">
                  <h3 className="mb-2">Notifications
                  {/* <div className="flex-grow-1 ms-2 name text-center m-1 ">
                <i style={{ color: "#000", cursor: "pointer" }} onClick={() => {
                 
                }} className="fa fa-paper-plane"></i>
              </div> */}
              <div style={{ cursor: 'pointer', visibility: 'visible', color: '#000000', float: 'right', marginTop: 5, transition: 'transform .5s linear', transformStyle: 'preserve-3D', position: 'sticky', rotationPoint: '50% 50%' }} >
              <i  style={{ cursor: 'pointer', visibility: 'visible', color: '#000000',  marginTop: 5, transition: 'transform .5s linear', transformStyle: 'preserve-3D', position: 'sticky',marginRight:20}} onClick={() => { handleShow()}} className="fa fa-paper-plane" />

                    <i id="refreshbtn" style={{ cursor: 'pointer', visibility: 'visible', color: '#000000',  marginTop: 5, transition: 'transform .5s linear', transformStyle: 'preserve-3D', position: 'sticky', rotationPoint: '50% 50%' }} onClick={() => { refreshlist() }} className="fas fa-sync" />
                    </div>
                  </h3>
                </CardHeader>
                {/* <Table striped hover responsive bordered className="align-items-center table-flush">
                  <thead className="thead-light">
                    <tr>  
                      <th scope="col" style={{ textAlign: "left" }}>No</th>
                      <th scope="col" style={{ textAlign: "left" }}>Token</th>
                      <th scope="col" style={{ textAlign: "left" }}>Platform</th>
                      <th scope="col" style={{ textAlign: "left" }}>App Version</th>
                      <th scope="col" style={{ textAlign: "left" }}>Create</th>
                    </tr>
                  </thead>
                  {notificationListRecords()}
                </Table> */}

                  <TableContainer
                    columns={columns}
                    data={deviceNotificationData || []}
                    customPageSize={10}
                    className="custom-header-css"
                  >
                  </TableContainer>
                {/* <CardFooter className="py-4">
                </CardFooter> */}
              </Card>
            </div>
          </Row>
        </Container>
      </div>

    </>
  );
}
export default Notifications;
