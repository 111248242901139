import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { deleteVideo } from "services/Device";

const DeleteVideoModal = ({ deleteModal, setDeleteModal, toggle, title, refreshData }) => {
    const deleteRecord = async () => {
        const apiBody = { title: title }
        const response = await deleteVideo(apiBody);
        if (response.result.type == "success") {
            setDeleteModal(false);
            refreshData()
        }
    }
    return (
        <Modal
            isOpen={deleteModal} toggle={toggle}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <ModalBody>
                <h3>Do you want to delete the record ?</h3>
            </ModalBody>
            <ModalFooter>
                <Button style={{ backgroundColor: "red", borderColor: "red", color:"#fff" }} onClick={() => { deleteRecord() }}>Delete</Button>
                <Button onClick={() => { setDeleteModal(false) }}>Close</Button>
            </ModalFooter>
        </Modal>
    )
}
export default DeleteVideoModal;