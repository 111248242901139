import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import AdminFooter from "../components/Footers/AdminFooter";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "../route/orgRoutes";

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  return (
    <>
      <Sidebar
        {...props}
        routes={routes.filter((data)=>data.path != '/device/:id')}
        logo={{
          innerLink: "/device-list",
          imgSrc: require("../assets/img/brand/argon-react.png").default,
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          routes={routes.indexOf(item => item.path !== undefined)}
        />
        <Switch>
          {routes.map((prop, key) => {
            if (prop.redirect) {
              return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
            }
            else {
              return (
                <Route path={prop.path} component={prop.component} key={key} />
              );
            }
          })}
        </Switch>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Admin;
