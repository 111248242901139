import {
  Button, Card, CardHeader, CardBody, FormGroup, Form, Input, InputGroupAddon,
  InputGroupText, InputGroup, Col, Spinner, Container, Row
} from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastLayout from './partials/ToastifyMsg';
import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import UserContext from "../components/UserContext";
import { userLoginAPI } from '../services/LoginUser';
import { setUserToken } from '../services/roleService'

const Login = () => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const userContext = useContext(UserContext);
  const history = useHistory()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false);
  const Loading = () => {
    return (<Spinner style={{ color: '#6c6c6c' }} animation="border" size="md" />);
  }

  const onLoginChange = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value)
    }
    if (e.target.name === "password") {
      setPassword(e.target.value)
    }
  }

  const doLogin = () => {
    setLoading(true);
    if (email) {
      var loginData = {
        email: email,
        password: password
      }
      userLoginAPI(loginData).then((result) => {
        console.log("result ------>", result);
        if (result.response == true) {
          setLoading(false);
          if (result.result.type == "success") {
            console.log("result", result.result.message)
            setUserToken(result.result.token);
            localStorage.setItem('userName', loginData.email);
            userContext.doLogin("admin");
            history.push("/device-list");
          } else {
            setLoading(false);
            setPassword("")
            toast.error(ToastLayout("Invalid Email or Password"));
          }
        } else {
          alert("Invalid login")
          setPassword("")
          setLoading(false);
        }
      }).catch((error) => {
        setEmail("");
        setPassword("");
        console.log("Error IS: ",error)
        // console.log(error);
      });
    }
    else {

      alert("Invalid username and password")
      setLoading(false);
    }
  }

  useEffect(() => {
    document.body.classList.add("bg-default");
    localStorage.clear();
    if (localStorage.getItem('userToken')) {
      history.push("/device-list")
    }
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  return (
    <>
      <div className="main-content" ref={mainContent} style={{ height: '100vh' }}>
        <div className="header py-6 py-lg-5">
          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <h1 style={{ color: "#000", fontSize: 30 }}>Welcome.....!</h1>
                  <p className="text-lead " style={{ color: "#000", fontWeight: "500" }}>
                    When loved ones are away,
                    But love finds its way! </p>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="shadow border-0" style={{ backgroundColor: "#fff" }}>
                <CardHeader className="bg-transparent">
                  <div className="btn-wrapper text-center"
                    style={{
                      marginLeft: '0px',
                      paddingRight: '0px',
                      paddingBottom: '0px',
                      paddingLeft: '0px',
                      borderBottomLeftRadius: '15px',
                      borderBottomRightRadius: '15px',
                      backgroundColor: 'transparent'
                    }}>
                    <img
                      style={{ width: '100%' }}
                      // alt="..."
                      src="images/TelepathyLogo.png"
                    />
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <Form role="form">
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          style={{ color: "#000" }}
                          name="email"
                          id="email"
                          placeholder="Email"
                          type="email"
                          onChange={(e) => onLoginChange(e)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          style={{ color: "#000" }}
                          name="password"
                          placeholder="Password"
                          type="password"
                          onChange={(e) => onLoginChange(e)}
                          autoComplete="new-password"
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      {loading ? Loading() :
                        <Button onClick={doLogin} style={{ background: "#d2c774", color: "#fff" }} type="button">
                          Sign in
                        </Button>}
                      <ToastContainer limit={1} />
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Login;
