import { useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Form, FormGroup, Label, CustomInput, Input } from "reactstrap"
import { getImageData, getVideoData, uploadVideo } from '../../services/Device';

const UploadVideoModal = ({ uploadVideoModal, setUploadVideoModal, toggle, refreshData }) => {
    const [title, setTitle] = useState("");
    const [fileData, setFileData] = useState({});
    const [thumbnailData, setThumbnailData] = useState({});
    const [errorDisplay, setErrorDisplay] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const handleClose = () => {
        setUploadVideoModal(false)
    }
    const onSubmit = async () => {
        fileData.title = title;
        const formData = new FormData();
        formData.append('video', fileData);
        var response = await getVideoData(formData);
        const formImageData = new FormData();
        formImageData.append('image', thumbnailData);
        var responseImage = await getImageData(formImageData);
        console.log("response=>",responseImage);
        if (response.result.type == "error") {
            setErrorMessage(`(${response.result.message})`)
            setErrorDisplay(true);
        } else {
            var apiBody = response.result.data;
            apiBody['title'] = title;
            apiBody['thumbnail'] = responseImage.result.data.filename;
            var responseUploadVideo = await uploadVideo(apiBody)
            if (responseUploadVideo.result.type == "success") {
                setFileData({});
                setThumbnailData({});
                setTitle("");
                setUploadVideoModal(false)
                refreshData()
            } else {
                setErrorMessage(`(${responseUploadVideo.result.message})`)
                setErrorDisplay(true);
            }
        }
    };
    return (
        <>
            <Modal
                isOpen={uploadVideoModal} toggle={toggle}>
                <ModalHeader >
                    <a style={{ fontSize: 'large' }}>Upload Video</a>
                </ModalHeader>
                <ModalBody>
                    <Form >
                        <FormGroup>
                            <Label className="floating-label" htmlFor="title" style={{ color: "#000" }}>Title <a style={{ color: "red", fontSize: "small" }}>*</a></Label>
                            <Input
                                onChange={(e) => { setTitle(e.target.value) }}
                                type="text"
                                name="title"
                                className="form-control-alternative"
                                id="categoryCode"
                                defaultValue={title}
                                placeholder="Enter Title"
                                required={true} />
                        </FormGroup>
                        <FormGroup>
                            <Label className="floating-label" htmlFor="title" style={{ color: "#000" }}>Choose Video <a style={{ color: "red", fontSize: "small" }}>*</a></Label>
                            <CustomInput
                                type="file"
                                name="uploaded-video"
                                id="video"
                                label="Add Video..."
                                className="floating-label"
                                onChange={(e) => { setFileData(e.target.files[0]) }}
                                required={true}
                            />
                            <a style={{ fontSize: 'small' }}>(File extension should be .3gp, .mkv or .mp4)</a>
                        </FormGroup>
                        <FormGroup>
                            <Label className="floating-label" htmlFor="title" style={{ color: "#000" }}>Choose Thumbnail <a style={{ color: "red", fontSize: "small" }}>*</a></Label>
                            <CustomInput
                                type="file"
                                name="uploaded-video"
                                id="video"
                                label="Add Thumbnail..."
                                className="floating-label"
                                onChange={(e) => { setThumbnailData(e.target.files[0]) }}
                                required={true}
                            />
                            <a style={{ fontSize: 'small' }}>(File extension should be .jpg, .jpeg or .png)</a>
                        </FormGroup>
                        {errorDisplay == true ? <a style={{ fontSize: 'small', color: "red" }}>{errorMessage}</a> : <></>}
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button style={{ backgroundColor: "#CFD2D8" }} variant="primary" onClick={() => { onSubmit() }}>
                        Submit
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}
export default UploadVideoModal;