import ReactPlayer from "react-player";
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { videoUrl } from "../../services/Consts";

const VideoPlayer = ({ videoDisplay, toggle, setVideoDisplay, path }) => {
    return (
        <Modal
            isOpen={videoDisplay} toggle={toggle}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <ModalHeader style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                <i className="fas fa-times-circle" style={{ fontSize: 21 }} onClick={() => { setVideoDisplay(false) }}></i>
            </ModalHeader>
            <ModalBody>
                <ReactPlayer
                    width={"100%"}
                    volume={1}
                    playing
                    url={`${videoUrl}${path}`}
                    controls
                />
            </ModalBody>
        </Modal>
    )
}
export default VideoPlayer;