/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react';
import { Button, Card, CardHeader, Container, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, Row } from "reactstrap";
import { Table, Spinner } from "reactstrap";
import Header from "components/Headers/Header.js";
import { deviceDataAPI, exportdeviceDataAPI } from '../services/Device';
import { getDateFormat } from 'services/Consts';
import MyVerticallyCenteredModal from '../components/Popup/Popup.js';
import TableContainer from '../components/TableContainer';


const Tables = () => {
  const [deviceData, setDeviceData] = useState([])
  const [deviceDataSearch, setDeviceDataSearch] = useState([])
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("")
  const [modalShow, setModalShow] = useState(false);
  const [record, setRecord] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [countSort, setCountSort] = useState(false);
  const [sortOptionSelect, setSortOptionSelect] = useState("");


  const refreshlist = () => {
    setLoading(true);
    document.getElementById("refreshbtn").className = "fas fa-sync fa-spin";
    getdata();
    if (loading) { setLoading(false) }
  }

  const getdata = async () => {
    setLoading(true);
    getAllDevice();
    getExportData();
  }

  const getExportData = async () => {
    exportdeviceDataAPI().then((data) => {
      setExportData(data)
      setLoading(false);
    })
  }

  const filterdata = async (text) => {
    text && text.length > 1 ?
      setDeviceData(deviceDataSearch.filter((item) => String(item.email).toLowerCase().includes(String(text).toLowerCase()))) : setDeviceData(deviceDataSearch)
  }
  const deleteRecord = (data) => {
    setRecord(data);
    setModalShow(true);
    // removeDeviceSession(data._id);
    // getdata();
    // return(
    // )
  }

  const getAllDevice = () => {
    deviceDataAPI().then(async (data) => {
      if (data.type == "success") {
        // let data = data.users.sort((a, b) => { return b.country - a.country })
        setDeviceData(data.users);
        setDeviceDataSearch(data.users);
        document.getElementById("refreshbtn").className = "fas fa-sync fa-stop"
      }
    })
  }

  const onSortChanges = async () => {
    var sortedDeviceData = ""
    if (sortOptionSelect == 1) {
      sortedDeviceData = await deviceData.sort((a, b) => { return a.count - b.count });
      setDeviceData(sortedDeviceData)
    } else if (sortOptionSelect == -1) {
      sortedDeviceData = await deviceData.sort((a, b) => { return b.count - a.count });
      setDeviceData(sortedDeviceData)
    } else if (sortOptionSelect == "") {
      setDeviceData(deviceDataSearch);
    }
    setCountSort(false);
  }

  const deviceListRecords = () => {
    if (deviceData.length > 0 && !loading) {
      return deviceData.filter(data => {
        if (query === "") {
          return data;
        }
        else {
          return data;
        }
      }).map((item, index) => {
        return (
          <tbody key={index}>
            <tr>
              <td style={{ color: "#000", textAlign: "left" }}>{(index + 1) || "-"}</td>
              <td style={{ color: "#000", textAlign: "left" }}><a href={'/#/device/' + item.email}>{item.email || "-"} </a></td>
              <td style={{ color: "#000", textAlign: "left" }}>{item.macId || "-"}</td>
              <td style={{ color: "#000", textAlign: "left" }}>{getDateFormat(item.updatedDate)}</td>
              <td style={{ color: "#000", textAlign: "left" }}>{item.count || 0}</td>
              <td style={{ color: "#000", textAlign: "left" }}><i className="fa fa-trash" aria-hidden="true" onClick={() => { deleteRecord(item) }}></i></td>
            </tr>
          </tbody>
        )
      })
    } else {
      return (
        <>
          {loading ?
            <tbody>
              <tr>
                <th colSpan="7">
                  <Spinner animation="border" size="md" />
                </th>
              </tr>
            </tbody>
            :
            <tbody>
              <tr >
                <th colSpan="7" style={{ textAlign: 'center' }}>No Record Found!</th>
              </tr>
            </tbody>
          }
        </>
      )
    }
  }

  useEffect(async () => {
    getdata()
  }, [])

  //Download CSV

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })


    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }


  const exportToCsv = e => {
    if (exportData.length === 0) {
      return
    }

    e.preventDefault()

    // Headers for each column
    let headers = ['Email,MacIds']

    // Convert users data to a csv
    let usersCsv = exportData.reduce((acc, { _id, macIds = [] }) => {
      const convertMacId = macIds.map((item) => (item.macId))
      acc.push([_id, convertMacId].join(','))
      return acc
    }, [])
    downloadFile({
      data: [...headers, ...usersCsv].join('\n'),
      fileName: 'users-details.csv',
      fileType: 'text/csv',
    })
  }

  // window.location.reload(true);

  return (
    <>
      <MyVerticallyCenteredModal
        recorditem={record}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <div>
        <Header />
        <Container className="mt--7" fluid style={{ marginTop: '-8rem !important' }}>
          <Row>
            <div className="col">
              <Card className="shadow" style={{ height: '85vh' }}>
                <CardHeader className="border-0">
                  <h3 className="mb-0">MQTT List
                    <div style={{ float: 'right' }}>
                      <i style={{ cursor: 'pointer', visibility: 'visible', color: '#000000', marginTop: 5, transition: 'transform .5s linear', margin: 30 }} onClick={exportToCsv} className="fa fa-download" />

                      <i id="refreshbtn" style={{ cursor: 'pointer', visibility: 'visible', color: '#000000', marginTop: 5, transition: 'transform .5s linear', transformStyle: 'preserve-3D', position: 'sticky', rotationPoint: '50% 50%' }} onClick={() => { refreshlist() }} className="fas fa-sync" />
                    </div>
                  </h3>
                  <div className="input-group" style={{ margin: 10, marginLeft: 0, width: "55%", height: 30 }}>
                    <span className="input-group-text text-body"><i className="fas fa-search" aria-hidden="true"></i></span>
                    <input type="text" className="form-control" placeholder="Search Your Email" onChange={event => {
                      filterdata(event.target.value)
                      // setQuery(event.target.value)
                    }
                    }
                    />
                  </div>
                </CardHeader>
                <Table striped hover responsive bordered className="align-items-center table-flush">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" style={{ textAlign: "left" }}>ID</th>
                      <th scope="col" style={{ textAlign: "left" }}>Email</th>
                      <th scope="col" style={{ textAlign: "left" }}>Lamp-MacID</th>
                      <th scope="col" style={{ textAlign: "left" }}>Updated Time</th>
                      <th scope="col" style={{ textAlign: "left" }}>Count
                        {/* <i className="fas fa-sort-up fa-lg" style={{marginLeft:5, color:"blue"}}></i> */}
                        <i className='fas fa-sort fa-lg' style={{ marginLeft: 5 }} onClick={() => { setCountSort(true) }}></i>
                      </th>
                      <th scope="col" style={{ textAlign: "left" }}>Delete</th>
                    </tr>
                  </thead>
                  {deviceListRecords()}
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={countSort} toggle={() => { setCountSort(!countSort) }}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <ModalBody>
          <h3>Sort Count</h3>
          <FormGroup check>
            <Label check style={{ width: "50%", alignItems: "center", justifyContent: "center" }}>
              <Input type="radio" name="radio1" onChange={(e) => { setSortOptionSelect(1) }} /> Ascending
            </Label>
            <Label check style={{ width: "50%", alignItems: "center", justifyContent: "center" }}>
              <Input type="radio" name="radio1" onChange={(e) => { setSortOptionSelect(-1) }} /> Descending
            </Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
          <Button style={{ marginTop: 0, backgroundColor: "red", color: "#fff", borderColor: "transparent" }} onClick={() => {
            setSortOptionSelect("");
            setDeviceData(deviceDataSearch);
            setCountSort(false);
          }}>Clear Sort</Button>
          <Button style={{ marginTop: 0, backgroundColor: "#112f63", color: "#fff", borderColor: "transparent" }} onClick={() => { onSortChanges() }}>Save Changes</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Tables;
