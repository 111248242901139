import { Link } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import React, { useContext } from 'react';
import { useHistory } from "react-router-dom";
import UserContext from "../../components/UserContext";

const AdminNavbar = () => {
  const userContext = useContext(UserContext);
  const history = useHistory();

  var doLogout = () => {
    userContext.doLogin("logged-out");
    history.push("/admin")
  }

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid style={{  }} >
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/">
          </Link>
          {/* <Media className="align-items-center">
            <span className="avatar avatar-sm rounded-circle">
            <i style={{ color: "#000", cursor: "pointer",  }} onClick={() => {
                  // handleShow(cellProps.row.original)
                }} className="fa fa-paper-plane"></i>
            </span>
            <Media className="ml-2 d-none d-lg-block">
              <span style={{color: 'black'}} className="mb-0 text-sm font-weight-bold">
                Send Notification
              </span>
            </Media>
          </Media> */}
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="log-out logo"
                      src={require("../../assets/img/theme/img_avatar.jpg")}
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      Admin
                    </span>
                  </Media>
                </Media>

              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem href="" onClick={doLogout}>
                  <i className='fas fa-sign-out-alt' style={{ color: 'red' }}></i>
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
