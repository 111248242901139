import React, { useState, useEffect } from 'react';
import { Card, CardHeader, Table, Container, Row, Spinner } from "reactstrap";
import Header from "components/Headers/Header.js";
import { getColorDataByEmail } from '../services/Device';
import { getDateFormat } from 'services/Consts';
// 


const deviceDetils = (match) => {
  // const [deviceSession] = useState([])
  const [loading, setLoading] = useState(false);
  const [colorData, setColorData] = useState([]);
  const refreshlist = () => {
    setLoading(true);
    // document.getElementById("refreshbtn").className = "fas fa-sync fa-spin";
    getdata();
    if (loading) { setLoading(false) }
  }

  const getdata = () => {
    setLoading(true);
    // getAllDeviceSession();
    getColorData();
  }

  useEffect(() => {
    getdata()
  }, [])

  // const getAllDeviceSession = () => {
  //   // console.log("getAllDeviceSession ", match);
  //   deviceSessionAPI(match.match.params.id).then(() => {
  //     // let sortedResult = data.user.sort((a, b) => b.createdAt - a.createdAt);
  //     // setDeviceSession(sortedResult)
  //     setLoading(false);
  //     // document.getElementById("refreshbtn").className = "fas fa-sync fa-stop"
  //   });
  // }

  const getColorData = async () => {
    let data = { email: match.match.params.id }
    const { result } = await getColorDataByEmail(data);
    console.log(">result ", result);
    if (result?.users?.length > 0) {
      setColorData(result.users);
      setLoading(false);
    }
  }

  const showRecords = () => {
    if (colorData.length > 0 && !loading) {
      return colorData.map((item, index) => {
        return (
          <tbody key={index}>
            <tr>
              <td style={{ color: "#000", textAlign: "center" }}>{item.topic || "-"}</td>
              <td style={{ color: "#000", textAlign: "left" }}>{item.updatedDate ? getDateFormat(item.updatedDate) : "-"}</td>
              <td style={{ backgroundColor: 'rgba(' + item.message + ',1)', height: 30, width: 30 }}></td>
            </tr>
          </tbody>
        )
      })
    } else {
      return (
        <>
          {loading ?
            <tbody>
              <tr>
                <th colSpan="10">
                  <Spinner animation="border" size="md" />
                </th>
              </tr>
            </tbody>
            :
            <tbody>
              <tr >
                <th colSpan="10" style={{ textAlign: 'center' }}>No Record Found!</th>
              </tr>
            </tbody>
          }
        </>
      )
    }
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid style={{ marginTop: '-8rem !important' }}>
        <Row>
          <div className="col">
            <Card className="shadow" style={{ height: '78.4vh' }}>
              <CardHeader className="border-0">
                <h3 className="mb-2">MQTT Events
                  <i id="refreshbtn" style={{ cursor: 'pointer', visibility: 'visible', color: '#000000', float: 'right', marginTop: 5, transition: 'transform .5s linear', transformStyle: 'preserve-3D', position: 'sticky', rotationPoint: '50% 50%' }} onClick={() => { refreshlist() }} className="fas fa-sync" />
                </h3>
              </CardHeader>
              <Table striped hover responsive bordered className="align-items-center table-flush">
                <thead className="thead-light">
                  <tr>
                    <th scope="col" style={{ textAlign: "center" }}>Topic</th>
                    <th scope="col" style={{ textAlign: "left" }}>Updated Date</th>
                    <th scope="col" style={{ textAlign: "left" }}>Color</th>
                    {/* <th scope="col">Delete data</th> */}
                  </tr>
                </thead>
                {showRecords()}
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
export default deviceDetils;
