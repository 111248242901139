import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
    useTable,
    useGlobalFilter,
    useFilters,
    useExpanded,
    usePagination,
    useRowSelect
} from "react-table";
import { Table, Row, Col, Button, Input } from "reactstrap";

const TableContainer = ({
    columns,
    data,
    customPageSize,
    trClass,
    divClass,
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0, pageSize: customPageSize, selectedRowIds: 0,
            },
        },
        useGlobalFilter,
        useFilters,
        useExpanded,
        usePagination,
        useRowSelect
    );

    const generateSortingIndicator = (column) => {
        return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
    };

    const onChangeInInput = (event) => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0;
        gotoPage(page);
    };

    return (
        <Fragment>

            <div className={divClass}>
                <Table striped hover responsive bordered className="align-items-center table-flush"{...getTableProps()}
                >
                    <thead
                        className="thead-light"
                    >
                        {headerGroups.map((headerGroup) => (
                            <tr className={trClass} key={headerGroup.id}  {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th key={column.id}
                                        className="text-center"
                                        style={{ color: "#000000", fontSize: "13px", fontWeight: "bold" }}
                                    >
                                        {column.render("Header")}
                                        {generateSortingIndicator(column)}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <Fragment key={row.getRowProps().key}>
                                    <tr>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td style={{ color: "#000"}} key={cell.id} {...cell.getCellProps()}>
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                </Fragment>
                            );
                        })}
                    </tbody>
                </Table>
            </div>

            <Row style={{ flex : 1, justifyContent: 'flex-end',alignItems: 'flex-end', marginBottom: 5 }}>
                <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                        <Button
                            size="m"
                            color="dark"
                            onClick={previousPage}
                            disabled={!canPreviousPage}
                        >
                            {"<"}
                        </Button>
                    </div>
                </Col>
                <Col style={{color: '#000000'}} className="col-md-auto d-none d-md-block mb-2">
                    Page{" "}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>
                </Col>
                <Col className="col-md-auto">
                    <Input
                        type="number"
                        min={1}
                        style={{ width: 70 }}
                        max={pageOptions.length}
                        defaultValue={pageIndex + 1}
                        onChange={onChangeInInput}
                    />
                </Col>

                <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                        <Button size="m" color="dark" onClick={nextPage} disabled={!canNextPage}>
                            {">"}
                        </Button>
                    </div>
                </Col>
            </Row>
            
        </Fragment>
    );
};

TableContainer.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;

